/*fonts*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
/*fonts*/

/*bootstrap*/
@import "~bootstrap/scss/bootstrap";
/*bootstrap*/

/*color vars*/
$blue: #006F9E;
$dark-white: #f3f3f4;
$white: #FFF;
$gray: #676a6c;
$bluegray: #45546b;
$red: #dc3545;
/*color vars*/

/*general assets*/
html {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 100%;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    background-color: $dark-white;
    color: $gray;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
}

.font-weight200 {font-weight: 200;}
.font-weight300 {font-weight: 300;}
.font-weight400 {font-weight: 400;}
.font-weight500 {font-weight: 500;}
.font-weight600 {font-weight: 600;}
.font-weight700 {font-weight: 700;}

h3 {
    margin-bottom: 0;
}

.marginauto{
    margin: 0 auto !important;
}

.btn-primary {
    background: $blue;
    border: $blue 1px solid;

    &:hover,
    &:focus,
    &.focus,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
        background: none;
        border: $blue 1px solid;
        color: $blue;
    }
}

.btn-danger {
    &:hover,
    &:focus,
    &.focus,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
        background: none;
        border: $red 1px solid;
        color: $red;
    }
}

.pagination {
    border-radius: 0;

    .page-item a {
        color: $blue;
        border-radius: 0;
    }

    .page-item a:hover {
        color: $white;
        background-color: $blue;
    }

    .page-item.active a {
        background-color: $blue;
    }

    .page-link.active, .active > .page-link {
        background-color: $blue;
    }

    .page-item:first-child .page-link {
        border-radius: 0;
    }

    .page-item:last-child .page-link {
        border-radius: 0;
    }
}
/*general assets*/

/*heading*/
#heading {
    background: $blue;
    color: $white;
    text-align: center;
    padding: 1em 0;

    .tit {
        font-size: 30px;
    }

    .subtit {
        font-size: 20px;
    }
}

.toolbar {
    .rounded-right-only {
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .pag {
        cursor: default;
        background-color: #006F9E;
        border-color: #006F9E;
        color: white;
    }
}
/*heading*/

/*login page*/
#loginpage {
    h2 {
        font-size: 24px;
        font-weight: 100;
    }

    button {
        font-size: 12px;
    }
}
/*login page*/

/*sidebar*/
#side-menu {
    .side-menu {
        width: 220px;
        height: 100vh;
        background: $blue;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
    }

    .welcome {
        background: $bluegray;
        color: $white;
        font-size: 1.5em;

        span {
            text-transform: capitalize;
        }
    }

    ul {
        margin: 0;
        padding: 0;
    }

    ul li {
        list-style-type: none;
        color: $white;
        border: 1px solid $blue;
    }

    ul li:hover {
        background: $bluegray;
        cursor: pointer;
    }

    a {
        color: $dark-white;
        text-decoration: none;
    }

    .active {
        background: $bluegray;
    }

    .d-flex {
        display: flex;
    }

    .justify-content-between {
        justify-content: space-between;
    }

    .align-items-center {
        align-items: center;
    }

    .right-icon {
        margin-left: auto !important;
    }

    /*logout btn*/
    .button-reset {
        background: none;
        border: none;
        margin: 0;
        font: inherit;
        color: inherit;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
    }
    /*logout btn*/
}
/*sidebar*/

/*dashboard*/
.inner-header {
    background: $white;
    border-top: 0;
    padding: 20px;
    width: calc(100% - 220px);
    float: right;

    h2 {
        margin-top: 10px;
        font-size: 26px;
        font-weight: 100;
    }
}
/*dashboard*/

/*page*/
.page-content {
    padding: 20px;
    width: calc(100% - 220px);
    float: right;

    .white-bg {
        background: $white;
    }

    h2 {
        margin-top: 10px;
        font-size: 26px;
        font-weight: 100;
    }

    .sort-link, .headers {
        text-decoration: none;
        color: $dark-white;
    }

    .sort-reset {
        text-decoration: none;
        color: $blue;

        &:hover {
            background: $blue;
            color: $white;
        }
    }

    .new-admin {
        text-decoration: none;
        color: $red;
    }

    .list-group-item-secondary {
        background: $bluegray;
    }

    .back-link {
        color: $white;
    }

    .form-check-input {
        width: 3rem;
        height: 1.5rem;
        border-radius: 1.5rem;

        &::before {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            top: 0;
            left: 0;
            transform: translateX(0.15rem);
        }

        &:checked {
            background-color: $blue;
            border-color: $blue;

            &::before {
                transform: translateX(1.35rem);
            }
        }
    }
}
/*page*/


/*footer*/
#footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    background: $white;
    z-index: 2024;
}
/*footer*/


/*media query*/
@media (max-width: 992px) {
    .side-menu {
        left: -220px;
        z-index: 2025;
    }

    .side-menu-open {
        left: 0 !important;
    }

    .page-content,
    .inner-header {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .remove-border-md-down {
        border: none transparent !important;
    }
}

@media (max-width: 576px) {
    #heading {
        .tit {
            font-size: 20px;
        }

        .subtit {
            font-size: 12px;
        }
    }

    .side-menu {
        left: -220px;
    }

    .page-content,
    .inner-header {
        width: 100%;
    }
}
/*media query*/
